// import Request from "./Request";
import RequestModal from "./RequestModal";
// import Search from "./Search";
import { useState } from "react";
// import background from "../assets/backdrop.svg";
import blackLogo from "../assets/logoblack.svg";
interface MainHeaderProps {
  onUpdate: (input: string) => void;
}

const MainHeader = (props: MainHeaderProps) => {
  const [input, setInput] = useState("");

  const handleChildUpdate = (e: string) => {
    setInput(e);
  };

  const [showModal, setShowModal] = useState(false);

  props.onUpdate(input);

  return (
    <div className="md:w-2/3 flex items-center flex-col justify-center mt-20 md:mt-20 md:px-0 px-5 lg:w-3/5">
      <img src={blackLogo} alt="plant icon" className="h-40 pr-1 opacity-80 object-contain" />
      {/* <h1 className="font-semi md:text-6xl text-center text-slate-900 text-4xl mt-4">
        Never overpay for houseplants, again.
      </h1> */}

      <h2 className="font-semibold text-xl text-center  text-slate-600 pt-3 md:w-3/4 ">
        Historical and real-time plant prices, tracked. <br/> Can't find
        your plant? Request it{" "}
        <span
          className=" hover:text-violet-900 text-violet-800 font-medium cursor-pointer"
          onClick={() => setShowModal(true)}
        >
          here.
        </span>
      </h2>
      {<RequestModal isOpen={showModal} setIsOpen={setShowModal} />}
     
    </div>
  );
};

export default MainHeader;
