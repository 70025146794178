import MainHeader from "../components/MainHeader";
import PlantGrid from "../components/PlantGrid";

import { useState } from "react";
import TopBar from "../components/Topbar";
import Footer from "../components/Footer";

const Homepage = () => {
  const [input, setInput] = useState("");

  const handleChildUpdate = (e: string) => {
    setInput(e);
  };

  return (
    <div className="overflow-x-hidden items-center justify-center flex flex-col">
      <TopBar />
      <MainHeader onUpdate={handleChildUpdate} />

      <PlantGrid searchResult={input} />
      <Footer />
    </div>
  );
};

export default Homepage;
