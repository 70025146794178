import { Routes, Route } from "react-router-dom";
import MainHeader from "./components/MainHeader";
import Modal from "./components/Modal";
import PlantGrid from "./components/PlantGrid";
import Search from "./components/Search";
import { useEffect, useState } from "react";
import AddPage from "./pages/AddPage";
import Homepage from "./pages/Homepage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/addplant" element={<AddPage />} />
    </Routes>
  );
}

export default App;
