import { Dialog, Transition } from "@headlessui/react";
import { useEffect, useState } from "react";

interface EmailModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const EmailModal = (props: EmailModalProps) => {
  const { isOpen, setIsOpen } = props;
  const [sent, setSent] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    const fname = e.target.fname.value;

    const lname = e.target.lname.value;

    const email = e.target.email.value.toString();

    const graphqlQuery = {
      query: `
      query Signup($email: String!, $fname: String!, $lname: String!){
          emailSignup(email: $email, fname: $fname, lname: $lname)
        }
      `,
      variables: {
        email,
        fname,
        lname,
      },
    };

    const request = await fetch(
      `${process.env.REACT_APP_BACKEND_SERVER}/graphql`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(graphqlQuery),
      }
    );
    const requestData = await request.json();

    setSent(true);
    console.log(requestData);
  };

  return (
    <Transition
      show={isOpen}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Dialog
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4 z-20">
          <Dialog.Panel
            id="modal"
            className="md:w-auto rounded-3xl bg-white sm:w- py-10 px-8 "
          >
            {!sent ? (
              <form onSubmit={onSubmit}>
                <div className="flex flex-col items-center justify-center">
                  <h1 className="text-4xl font-bold text-slate-800 w-80 text-center">
                    Sign Up For Our Email List
                  </h1>
                  <h2 className="text-slate-500 text-md font-normal mt-2 w-80">
                    Sign up to stay up to date on new plants, weekly price
                    trends, and more!
                  </h2>

                  <div className="flex flex-col items-left justify-center mt-10">
                    <label
                      className="text-slate-600 text-lg font-medium"
                      htmlFor="fname"
                    >
                      First Name
                    </label>
                    <input
                      className="border-2 border-slate-400 rounded-lg w-80 h-10 p-2  focus:ring-2 focus:ring-violet-600 focus:border-transparent"
                      type="text"
                      name="fname"
                      id="fname"
                      placeholder="Jack"
                      required
                      minLength={3}
                      maxLength={50}
                    />
                    <label
                      className="text-slate-600 text-lg font-medium  mt-2"
                      htmlFor="lname"
                    >
                      Last Name
                    </label>
                    <input
                      className="border-2 border-slate-400 rounded-lg w-80 h-10 p-2  focus:ring-2 focus:ring-violet-600 focus:border-transparent"
                      type="text"
                      name="lname"
                      id="lname"
                      placeholder="Ryan"
                      required
                      minLength={3}
                      maxLength={50}
                    />
                    <label
                      className="text-slate-600 text-lg font-medium  mt-2"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      className="border-2 border-slate-400 rounded-lg w-80 h-10 p-2  focus:ring-2 focus:ring-violet-600 focus:border-transparent"
                      type="email"
                      name="email"
                      id="email"
                      placeholder="notasecretagent@gmail.com"
                      required
                      minLength={3}
                      maxLength={50}
                    />
                  </div>
                </div>

                <div className="flex flex-col items-center justify-center mt-5">
                  <button
                    type="submit"
                    className="rounded-full bg-violet-900/80 hover:bg-violet-900/80 cursor-pointer w-1/2  h-12 text-white font-semibold text-lg transition-colors"
                  >
                    Submit
                  </button>
                </div>
              </form>
            ) : (
              <>
                <div className="flex flex-col items-center justify-center">
                  <h1 className="text-4xl font-bold text-violet-900">
                    Email added!
                  </h1>
                  <h2 className="text-slate-500 text-lg font-normal mt-2 w-96 text-center">
                    Plant reports go out at the end of the week. In the
                    meantime, sit back and browse for more plants. (Don't tell
                    your spouse.)
                  </h2>
                  <button
                    onClick={closeModal}
                    className="rounded-full bg-violet-900/80 hover:bg-violet-900/90 cursor-pointer w-full h-12 text-white font-semibold text-lg transition-colors mt-10"
                  >
                    Done
                  </button>
                </div>
              </>
            )}
          </Dialog.Panel>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EmailModal;
