import AddPlant from "../components/AddPlant";
import AddPlantType from "../components/AddPlantType";

const AddPage = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-green-50 ">
      {/* <AddPlant /> */}
      {/* <AddPlantType /> */}
    </div>
  );
};

export default AddPage;
