import { useState } from "react";
import EmailModal from "./EmailModal";
import plantIcon from "../assets/planticon.svg";
import whiteLogo from "../assets/whitelogo.png";

const TopBar = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className="w-full relative opacity-80 top-0 left-0 bg-violet-800 px-4 md:px-10 text-center  flex items-center justify-center py-3 md:py-2 ">
        <h5 className="text-slate-50 font-semibold text-lg md:text-base">
          {/* We're undergoing some maintenance, please be patient if things aren't working. */}
          Sign up for{" "}
          <span
            className="underline underline-offset-2 hover:text-slate-200 cursor-pointer"
            onClick={() => setShowModal(true)}
          >
            our market report emails!
          </span>{" "}
          📧
        </h5>
      </div>

      <EmailModal isOpen={showModal} setIsOpen={setShowModal} />
    </>
  );
};

export default TopBar;
