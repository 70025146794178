import whiteLogo from '../assets/whitelogo.png'

const Footer = ()=>{
    return (
        <div className="footer">
            <div className="bg-violet-800 w-screen flex justify-center items-center">
                <img src={whiteLogo} alt="plant icon" className="h-32" />
                </div>
        </div>
    )
}

export default Footer;