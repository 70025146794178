import { useEffect, useRef, useState } from "react";
import Chart from "./Chart";
import React from "react";
import { FocusOn } from "react-focus-on";
import { Dialog, Transition } from "@headlessui/react";

interface PlantItem {
  plant: {
    name: string;
    image: string;
    etsyMedian: number;
    etsyAverage: number;
    ebayMedian: number;
    ebayAverage: number;
    type: string;
    lastSold: string;
    lastUpdated: string;
    averagePrice: number;
  };
}

interface RequestModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  plant: PlantItem;
}

const Modal = (props: RequestModalProps) => {
  const { isOpen, setIsOpen } = props;

  const formattedSoldDate = (date: any) => {
    const dateObj = new Date(date++);
    return `${
      dateObj.getMonth() + 1
    }/${dateObj.getDate()}/${dateObj.getFullYear()}`;
  };

  const formattedUpdatedDate = (date: any) => {
    const dateObj = new Date(date++);

    return `${dateObj.getMonth() + 1}/${dateObj.getDate()} ${String(
      dateObj.getHours()
    ).padStart(2, "0")}:${String(dateObj.getMinutes()).padStart(2, "0")} EST`;
  };

  interface PlantPrice {
    ebay_average_price: number;
    ebay_median_price: number;
    etsy_average_price: number;
    etsy_median_price: number;
    date: number;
  }

  const [plantPrices, setPlantPrices] = useState<PlantPrice[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [selected, setSelected] = useState("etsyAv");

  const [size, setSize] = useState("cutting");

  const [estimatedPrice, setEstimatedPrice] = useState(0);

  let focusRef: any = useRef(null);

  useEffect(() => {
    const multiples: any = {
      cutting: 0.5,
      small: 0.75,
      medium: 1,
      large: 1.25,
    };

    const selectedObj: any = {
      etsyAv: props.plant.plant.etsyAverage,
      etsyMed: props.plant.plant.etsyMedian,
      ebayAv: props.plant.plant.ebayAverage,
      ebayMed: props.plant.plant.ebayMedian,
    };

    setEstimatedPrice(
      Math.round(selectedObj[selected] * multiples[size] * 100) / 100
    );
  }, [size, selected]);

  const fetchPlant = async () => {
    setIsLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_SERVER}/graphql`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          query: `query  {
            lookupPrices(plantName: "${props.plant.plant.name}") {
              ebay_average_price
              ebay_median_price
              etsy_average_price
              etsy_median_price
              average_price
              date
            }
          }`,
        }),
      }
    );
    const responseData = await response.json();

    setPlantPrices(responseData.data.lookupPrices);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchPlant();
  }, []);

  const percentChange = (current: number, previous: number) => {
    return ((current - previous) / previous) * 100;
  };

  return (
    <>
      {!isLoading && (
        <Dialog
          initialFocus={focusRef}
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <div
            className="fixed inset-0 bg-black/30 max-w-screen "
            aria-hidden="true"
          />

          <div className="fixed inset-0 flex md:items-start  justify-center p-4 overflow-y-auto overflow-x-hidden fullWidth z-20">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 fixed top-8 right-5 cursor-pointer z-10"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
            <Dialog.Panel className="md:w-auto relative rounded-3xl bg-white fullWidth ">
              <div className="flex flex-col md:flex-row items-center justify-center m-10 ">
                <img
                  className="rounded-xl w-56 h-56 object-cover"
                  src={props.plant.plant.image}
                />{" "}
                <div className="w-96">
                  <h2
                    className=" text-4xl font-semibold text-slate-900 px-5 pb-2 md:mt-0 mt-5 md:text-left text-center md:pl-5
                  "
                    ref={focusRef}
                  >
                    {props.plant.plant.name}
                  </h2>
                  <h5 className="text-xl font-normal text-slate-700 pl-5 text-center md:text-left">
                    Last updated:{" "}
                    {formattedUpdatedDate(props.plant.plant.lastUpdated)}
                  </h5>
                </div>
              </div>
              <div className="flex-col flex align-center items-center px-10 pb-4  bg-white">
                <h3 className="text-xl font-semibold text-slate-900 ">
                  All time price history: (Ebay)
                </h3>
                <Chart plantPrices={plantPrices} />
              </div>

              {plantPrices.length >= 2 && (
                <div className="w-full bg-slate-100 flex items-center justify-center pb-10   flex flex-col">
                  <h4 className="text-slate-800 font-semibold text-lg m-3 flex md:w-11/12 ">
                    Last Sold: {formattedSoldDate(props.plant.plant.lastSold)}
                  </h4>
                  <div className="flex  flex-col w-full md:w-fit items-center">
                    <div className="flex flex-col m-2 md:mb-4  md:flex-row w-2/3 justify-between  drop-shadow-md bg-white rounded-lg md:w-11/12 md:h-28 items-center">
                      <div className="flex flex-col p-5 border-e-">
                        <h4 className="text-slate-600">Average Price</h4>
                        <div className="flex">
                          <h4 className="text-slate-950 font-semibold text-2xl ">
                            ${props.plant.plant.averagePrice}
                          </h4>
                        </div>
                      </div>
                      <div className="flex flex-col p-5">
                        <h4 className="text-slate-600">Etsy Average Price</h4>
                        <div className="flex">
                          <h4 className="text-slate-950 font-semibold text-2xl ">
                            ${props.plant.plant.etsyAverage}
                          </h4>
                          {plantPrices.length >= 2 &&
                          percentChange(
                            plantPrices[0].etsy_average_price,
                            plantPrices[1].etsy_average_price
                          ) > 0 ? (
                            <div className="rounded-lg bg-green-200/50 text-green-800 font-semibold p-1 ml-1">
                              +{""}
                              {percentChange(
                                plantPrices[0].etsy_average_price,
                                plantPrices[1].etsy_average_price
                              ).toFixed(2)}
                              %
                            </div>
                          ) : (
                            <div className="rounded-lg bg-red-200/50 text-red-800 font-semibold p-1 ml-1">
                              {plantPrices.length >= 2 &&
                                percentChange(
                                  plantPrices[0].etsy_average_price,
                                  plantPrices[1].etsy_average_price
                                ).toFixed(2)}
                              %
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col p-5">
                        <h4 className="text-slate-600">Etsy Median Price</h4>
                        <div className="flex">
                          <h4 className="text-slate-950 font-semibold text-2xl ">
                            ${props.plant.plant.etsyMedian}
                          </h4>
                          {plantPrices.length >= 2 &&
                          percentChange(
                            plantPrices[0].etsy_median_price,
                            plantPrices[1].etsy_median_price
                          ) > 0 ? (
                            <div className="rounded-lg bg-green-200/50 text-green-800 font-semibold p-1 ml-1">
                              {percentChange(
                                plantPrices[0].etsy_median_price,
                                plantPrices[1].etsy_median_price
                              ).toFixed(2)}
                              %
                            </div>
                          ) : (
                            <div className="rounded-lg bg-red-200/50 text-red-800 font-semibold p-1 ml-1">
                              {plantPrices.length >= 2 &&
                                percentChange(
                                  plantPrices[0].etsy_median_price,
                                  plantPrices[1].etsy_median_price
                                ).toFixed(2)}
                              %
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col m-2 md:m-0 md:flex-row  w-2/3 justify-between mb-4 drop-shadow-md bg-white rounded-lg md:w-11/12 md:h-28 items-center">
                      <div className="flex flex-col p-5 border-e-">
                        <h4 className="text-slate-600">Number of Days</h4>
                        <div className="flex">
                          <h4 className="text-slate-950 font-semibold text-2xl ">
                            {plantPrices.length}
                          </h4>
                        </div>
                      </div>
                      <div className="flex flex-col p-5">
                        <h4 className="text-slate-600">Ebay Average Price</h4>
                        <div className="flex">
                          <h4 className="text-slate-950 font-semibold text-2xl ">
                            ${props.plant.plant.ebayAverage}
                          </h4>
                          {plantPrices.length >= 2 &&
                          percentChange(
                            plantPrices[0].ebay_average_price,
                            plantPrices[1].ebay_average_price
                          ) > 0 ? (
                            <div className="rounded-lg bg-green-200/50 text-green-800 font-semibold p-1 ml-1">
                              +{""}
                              {percentChange(
                                plantPrices[0].ebay_average_price,
                                plantPrices[1].ebay_average_price
                              ).toFixed(2)}
                              %
                            </div>
                          ) : (
                            <div className="rounded-lg bg-red-200/50 text-red-800 font-semibold p-1 ml-1">
                              {plantPrices.length >= 2 &&
                                percentChange(
                                  plantPrices[0].ebay_average_price,
                                  plantPrices[1].ebay_average_price
                                ).toFixed(2)}
                              %
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col p-5">
                        <h4 className="text-slate-600">Ebay Median Price</h4>
                        <div className="flex">
                          <h4 className="text-slate-950 font-semibold text-2xl ">
                            ${props.plant.plant.ebayMedian}
                          </h4>
                          {plantPrices.length >= 2 &&
                          percentChange(
                            plantPrices[0].ebay_median_price,
                            plantPrices[1].ebay_median_price
                          ) > 0 ? (
                            <div className="rounded-lg bg-green-200/50 text-green-800 font-semibold p-1 ml-1">
                              +{""}
                              {percentChange(
                                plantPrices[0].ebay_median_price,
                                plantPrices[1].ebay_median_price
                              ).toFixed(2)}
                              %
                            </div>
                          ) : (
                            <div className="rounded-lg bg-red-200/50 text-red-800 font-semibold p-1 ml-1">
                              {percentChange(
                                plantPrices[0].ebay_median_price,
                                plantPrices[1].ebay_median_price
                              ).toFixed(2)}
                              %
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="flex flex-col items-center justify-center py-4 bg-white rounded-b-3xl mb-10 w-full">
                <h3 className="text-lg font-semibold text-slate-900">
                  Estimated Price Based on Size
                </h3>
                <div className="flex flex-row items-center justify-center p-2 ">
                  <h4 className="text-md font-normal text-slate-800 ">
                    Data Source:
                  </h4>
                  <select
                    className="mx-2 border-2 font-medium italic text-indigo-900 p-2"
                    onChange={(e) => setSelected(e.target.value)}
                  >
                    <option value="etsyAv">Etsy Average</option>
                    <option value="etsyMed">Etsy Median</option>
                    <option value="ebayAv">Ebay Average</option>
                    <option value="ebayMed">Ebay Median</option>
                  </select>
                </div>
                <div className="grid grid-cols-4 grid-rows-1 text-center py-4 ">
                  <input
                    type="radio"
                    name="size"
                    id="cutting"
                    hidden
                    onClick={(e) => setSize((e.target as HTMLInputElement).id)}
                  />
                  <div
                    onClick={(e) =>
                      setSize(
                        (
                          (e.target as HTMLDivElement)
                            .firstChild as HTMLLabelElement
                        ).htmlFor
                      )
                    }
                    className={` flex items-center justify-center md:p-10 p-4 mx-1 border-2 border-indigo-200 drop-shadow-lg bg-white rounded hover:border-indigo-300 cursor-pointer ${
                      size === "cutting" ? "border-indigo-400" : ""
                    }`}
                  >
                    <label
                      htmlFor="cutting"
                      className=" text-slate-900 font-medium text-lg"
                    >
                      Cutting
                    </label>
                  </div>
                  <input
                    type="radio"
                    name="size"
                    id="small"
                    hidden
                    onClick={(e) => setSize((e.target as HTMLInputElement).id)}
                  />
                  <div
                    onClick={(e) =>
                      setSize(
                        (
                          (e.target as HTMLDivElement)
                            .firstChild as HTMLLabelElement
                        ).htmlFor
                      )
                    }
                    className={`flex items-center justify-center md:p-10 mx-1 border-2 border-indigo-200 drop-shadow-lg bg-white rounded hover:border-indigo-300 cursor-pointer ${
                      size === "small" ? "border-indigo-400" : ""
                    }`}
                  >
                    <label
                      htmlFor="small"
                      className=" text-slate-900 font-medium text-lg"
                    >
                      Small
                    </label>
                  </div>
                  <input
                    type="radio"
                    name="size"
                    id="medium"
                    hidden
                    onClick={(e) => setSize((e.target as HTMLInputElement).id)}
                  />
                  <div
                    onClick={(e) =>
                      setSize(
                        (
                          (e.target as HTMLDivElement)
                            .firstChild as HTMLLabelElement
                        ).htmlFor
                      )
                    }
                    className={`flex items-center justify-center md:p-10 mx-1 border-2 border-indigo-200 drop-shadow-lg bg-white rounded hover:border-indigo-300 cursor-pointer ${
                      size === "medium" ? "border-indigo-400" : ""
                    }`}
                  >
                    <label
                      htmlFor="medium"
                      className=" text-slate-900 font-medium text-lg"
                    >
                      Medium
                    </label>
                  </div>
                  <input
                    type="radio"
                    name="size"
                    id="large"
                    hidden
                    onClick={(e) => setSize((e.target as HTMLInputElement).id)}
                  />
                  <div
                    onClick={(e) =>
                      setSize(
                        (
                          (e.target as HTMLDivElement)
                            .firstChild as HTMLLabelElement
                        ).htmlFor
                      )
                    }
                    className={`flex items-center justify-center md:p-10 mx-1 border-2 border-indigo-200 drop-shadow-lg bg-white rounded hover:border-indigo-300 cursor-pointer ${
                      size === "large" ? "border-indigo-400" : ""
                    }`}
                  >
                    <label
                      htmlFor="large"
                      className=" text-slate-900 font-medium text-lg"
                    >
                      Large
                    </label>
                  </div>
                </div>
                <h3 className="text-slate-800 font-medium">
                  Estimated Price:{" "}
                  <span className="text-2xl font-semibold text-green-700 italic">
                    ${estimatedPrice}
                  </span>
                </h3>
              </div>
            </Dialog.Panel>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default Modal;
