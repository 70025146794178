import { Dialog, Transition } from "@headlessui/react";
import { useEffect, useState } from "react";

interface RequestModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const RequestModal = (props: RequestModalProps) => {
  const { isOpen, setIsOpen } = props;
  const [sent, setSent] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
    setSent(false);
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();


    const name = e.target.name.value;

    const category = e.target.category.value;

    const graphqlQuery = {
      query: `
        query{
            emailRequest(plantName: "${name}", category: "${category}")
        }

        `,
    };

    const request = await fetch(
      `${process.env.REACT_APP_BACKEND_SERVER}/graphql`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(graphqlQuery),
      }
    );
    const requestData = await request.json();
    setSent(true);
  };

  return (
    <Transition
      show={isOpen}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Dialog
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4 z-20">
          <Dialog.Panel
            id="modal"
            className="md:w-auto rounded-3xl bg-white sm:w- py-10 px-20 "
          >
            {!sent ? (
              <form onSubmit={onSubmit}>
                <div className="flex flex-col items-center justify-center">
                  <h1 className="text-4xl font-bold text-violet-900">
                    Request a Plant
                  </h1>
                  <h2 className="text-slate-500 text-lg font-normal mt-2">
                    Can't find your plant? Request it here.
                  </h2>
                </div>

                <div className="flex flex-col items-left justify-center mt-10">
                  <label
                    className="text-slate-600 text-lg font-medium"
                    htmlFor="name"
                  >
                    Plant Name
                  </label>
                  <input
                    className="border-2 border-slate-400 rounded-lg w-80 h-10 p-2  focus:ring-2 focus:ring-violet-600 focus:border-transparent"
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Monstera Deliciosa"
                    required
                    minLength={3}
                    maxLength={100}
                  />
                  <label
                    className="text-slate-600 text-lg font-medium  mt-2"
                    htmlFor="category"
                  >
                    Plant Category
                  </label>
                  <input
                    className="border-2 border-slate-400 rounded-lg w-80 h-10 p-2  focus:ring-2 focus:ring-violet-600 focus:border-transparent"
                    type="text"
                    name="category"
                    id="category"
                    placeholder="Monstera"
                    required
                    minLength={3}
                    maxLength={100}
                  />
                </div>
                <div className="flex flex-col items-center justify-center mt-5">
                  <button
                    type="submit"
                    className="rounded-full bg-violet-900/80 hover:bg-violet-900/80 cursor-pointer w-full h-12 text-white font-semibold text-lg transition-colors"
                  >
                    Submit
                  </button>
                </div>
              </form>
            ) : (
              <>
                <div className="flex flex-col items-center justify-center">
                  <h1 className="text-4xl font-bold text-violet-900">
                    Request Sent!
                  </h1>
                  <h2 className="text-slate-500 text-lg font-normal mt-2 w-96 text-center">
                    In the meantime, make sure you sign up for our email list to
                    get notified when we add new plants and weekly price trend
                    updates.
                  </h2>
                  <button
                    onClick={closeModal}
                    className="rounded-full bg-violet-900/80 hover:bg-violet-900/90 cursor-pointer w-full h-12 text-white font-semibold text-lg transition-colors mt-10"
                  >
                    Done
                  </button>
                </div>
              </>
            )}
          </Dialog.Panel>
        </div>
      </Dialog>
    </Transition>
  );
};

export default RequestModal;
