interface CategoryPillProps {
  category: string;
  selected?: string;
  onSelect?: (category: string) => void;
}

const CategoryPill = (props: CategoryPillProps) => {
  const selected = props.selected == props.category;

  return (
    <div
      onClick={() => props.onSelect && props.onSelect(props.category)}
      className={`rounded-full my-8 mx-2 customColor hover:bg-violet-900/80 cursor-pointer min-w-fit ${
        selected ? "bg-violet-900/80" : ""
      }`}
    >
      <div className="flex items-center justify-center ">
        <h4 className="text-white font-semibold text-md m-3">
          {props.category}
        </h4>
      </div>
    </div>
  );
};

export default CategoryPill;
