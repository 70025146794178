import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

interface PlantPrice {
  ebay_average_price: number;
  ebay_median_price: number;
  etsy_average_price: number;
  etsy_median_price: number;
  date: number;
}

interface ChartProps {
  plantPrices: PlantPrice[];
}

const Chart = ({ plantPrices }: ChartProps) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        reverse: true,
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        // min: 0,
      },
    },
  };

  const labels = plantPrices.map((price: any) => {
    const dateObj = new Date(price.date++);
    return `${dateObj.getMonth() + 1}/${dateObj.getDate()}`;
  });

  const data = {
    labels,
    datasets: [
      {
        data: plantPrices.map((price: any) => price.ebay_average_price),
        borderColor: "rgb(9, 121, 105)",
        backgroundColor: "rgba(9, 121, 105, 0.5)",
      },
    ],
  };


  return <Line options={options} data={data} />;
};
export default Chart;
