import { useEffect, useState } from "react";
import CategoryPill from "./CategoryPill";
import PlantItem from "./PlantItem";

interface PlantProps {
  searchResult: string;
}
const PlantGrid = (props: PlantProps) => {
  const [selectedCategory, setSelectedCategory] = useState<string>("View All");
  const handleSelection = (category: string) => {
    setSelectedCategory(category);
  };

  const [fetchedPlants, setFetchedPlants] = useState([]);
  const [fetchedCategories, setFetchedCategories] = useState(["View All"]);

  const fetchCategories = async () => {
    const graphqlQuery = {
      query: `
      query  {
        lookupTypes
      }
      `,
    };
    const categories = await fetch(
      `${process.env.REACT_APP_BACKEND_SERVER}/graphql`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(graphqlQuery),
      }
    );

    const categoriesData = await categories.json();

    setFetchedCategories(
      categoriesData.data.lookupTypes.sort((a: string, b: string) =>
        a.localeCompare(b)
      )
    );
  };

  const fetchPlants = async () => {
    const graphqlQuery = {
      query: `
      query {
        lookupAllPlants {
          name
          image
          etsyMedian
          etsyAverage
          ebayMedian
          ebayAverage
          type
          lastSold
          lastUpdated
          averagePrice
        }
      }
      `,
    };
    const plants = await fetch(
      `${process.env.REACT_APP_BACKEND_SERVER}/graphql`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(graphqlQuery),
      }
    );

    const plantsData = await plants.json();

    setFetchedPlants(
      plantsData.data.lookupAllPlants.sort((a: any, b: any) =>
        a.name.localeCompare(b.name)
      )
    );
  };

  useEffect(() => {
    fetchPlants();
    fetchCategories();
  }, []);

  return (
    <div className="flex flex-wrap justify-center w-screen bg-slate-100 mt-16 md:mt-28 relative ">
      <div className="custom-shape-divider-top-1690396892">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>
      <div className="noscroll flex overflow-x-auto z-10">
        <CategoryPill
          category={"View All"}
          onSelect={handleSelection}
          selected={selectedCategory}
        />
        {fetchedCategories.map((category: string) => {
          return (
            <CategoryPill
              category={category}
              key={Math.random()}
              onSelect={handleSelection}
              selected={selectedCategory}
            />
          );
        })}
      </div>
      <div className=" w-full grid pb-10 pt-2 px-10 lg:px-20 gap-4 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 ">
        {fetchedPlants.map((plant: any) => {
          const isNameMatched = plant.name
            .toLowerCase()
            .includes(props.searchResult.toLowerCase());
          const isTypeMatched = plant.type === selectedCategory;

          if (
            (selectedCategory === "View All" && props.searchResult === "") || // If no filter is applied, show all plants.
            (props.searchResult !== "" &&
              selectedCategory === "View All" &&
              isNameMatched) || // If only searchResult is given, filter by name.
            (props.searchResult === "" &&
              selectedCategory !== "View All" &&
              isTypeMatched) || // If only category is given, filter by category.
            (props.searchResult !== "" &&
              selectedCategory !== "View All" &&
              isNameMatched &&
              isTypeMatched) // If both are given, filter by both.
          ) {
            return <PlantItem key={Math.random()} plant={plant} />;
          }
        })}
      </div>
    </div>
  );
};

export default PlantGrid;
