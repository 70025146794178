import { useState, useEffect, useRef } from "react";
import Modal from "./Modal";

interface PlantProps {
  plant: {
    name: string;
    image: string;
    etsyMedian: number;
    etsyAverage: number;
    ebayMedian: number;
    ebayAverage: number;
    type: string;
    lastSold: string;
    lastUpdated: string;
    averagePrice: number;
  };
}

const PlantItem = (props: PlantProps) => {
  const ref = useRef<null | HTMLImageElement>(null);

  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen);
    // ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClick = (e: any) => {
    if (e.target === e.currentTarget) {
      toggleModal();
    }
  };

  const formattedSoldDate = (date: any) => {
    const dateObj = new Date(date++);
    return `${
      dateObj.getMonth() + 1
    }/${dateObj.getDate()}/${dateObj.getFullYear()}`;
  };

  const formattedUpdatedDate = (date: any) => {
    const dateObj = new Date(date++);

    return `${dateObj.getMonth() + 1}/${dateObj.getDate()}  ${String(
      dateObj.getHours()
    ).padStart(2, "0")}:${String(dateObj.getMinutes()).padStart(2, "0")} EST`;
  };
  useEffect(() => {
    if (modalOpen) {
      const element = document.getElementById("fullHeight") as HTMLElement;
      if (element) {
        const modalHeight = document.getElementById("modal")?.offsetHeight;
        if (modalHeight && modalHeight > document.body.scrollHeight) {
          element.style.height = `${modalHeight + 400}px`;
        } else {
          element.style.height = `${document.body.scrollHeight}px`;
        }
      }
    }
  }, [modalOpen]);

  useEffect(() => {
    if (modalOpen && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [modalOpen]);

  return (
    <>
      <Modal plant={props} isOpen={modalOpen} setIsOpen={toggleModal} />
      <div
        className="w-full bg-white rounded-lg border-2 border-slate-400 cursor-pointer hover:border-slate-600"
        onClick={toggleModal}
      >
        <div className="flex items-center bg-violet-50 p-5 rounded-lg">
          <img
            className="h-16 w-16 rounded-full object-cover"
            src={props.plant.image}
          />
          <h4 className="pl-5 text-xl font-semibold text-violet-950">
            {props.plant.name}
          </h4>
        </div>
        <div>
          <div className="m-5 flex flex-col ">
            <div className="flex items-center w-full justify-between">
              <h4 className="text-slate-600">Average Price</h4>
              <div className="flex">
                {/* <h4 className="text-slate-950 font-semibold text-2xl ">
                  ${props.plant.averagePrice}
                </h4> */}
                <div className="rounded-lg bg-green-200/50 text-green-800 font-semibold p-1 ml-1 text-xl">
                  ${props.plant.averagePrice}
                </div>
              </div>
            </div>
            <div className="flex items-center w-full justify-between">
              <h4 className="text-slate-600">Last Sold</h4>
              <h5 className="text-slate-950 font-semibold text-md">
                {formattedSoldDate(props.plant.lastSold)}
              </h5>
            </div>
            <div className="flex items-center w-full justify-between">
              <h4 className="text-slate-600">Last Updated</h4>
              <h5 className="text-slate-950 font-semibold text-md">
                {formattedUpdatedDate(props.plant.lastUpdated)}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlantItem;
